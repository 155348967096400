.burger-wrapper {
  display: inline-flex;
  padding-top: 5px;
}
#burger-icon {
  width: 50px;
  height: 35px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 999;
}
#burger-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 75%;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#burger-icon span:nth-child(1) {
  top: 4px;
}
#burger-icon span:nth-child(2),
#burger-icon span:nth-child(3) {
  top: 14px;
}
#burger-icon span:nth-child(4) {
  top: 24px;
}
#burger-icon.burger-active span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
#burger-icon.burger-active span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#burger-icon.burger-active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#burger-icon.burger-active span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
@media screen and (max-width: 1100px) {
  #burger-icon span {
    height: 4px;
    width: 65%;
  }
}
