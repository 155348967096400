.navlink-active h4 {
  font-weight: 600;
}

.logo {
  font-family: var(--font-style-logo);
  padding: 20px 0;
  margin: 0;
  font-weight: 100;
  background-color: var(--color-black);
}
