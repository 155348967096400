:root {
  --color-blue: #00205b;
  --color-white: #fff;
  --color-black: #050506;
  --color-red: #e12120;
  --color-mint: #11c4a9;
  --color-yahoo: #6001d2;
  --color-grey: #a9a9a9;
  --color-lightgrey: #a9a9a9;
  --font-size-xxs: 0.8rem;
  --font-size-xs: 1rem;
  --font-size-s: 1.1rem;
  --font-size-m: 1.5rem;
  --font-size-lg: 2rem;
  --font-size-xlg: 2.5rem;
  --font-size-xxlg: 2.75rem;
  --font-style-heading: 'Noto Sans JP', sans-serif;
  --font-style-default: 'Noto Sans JP', sans-serif;
  /* --font-style-logo: 'Fugaz One', cursive; */
  --font-style-logo: 'Bangers', monospace;
}

body {
  margin: 0;
  font-size: var(--font-size-s);
  font-family: var(--font-style-default);
  color: var(--color-black);
  background: #f5f6f7;
  /* min-height: 100vh; */
}

h1,
h2,
h3,
h4,
h5 {
  color: inherit;
  font-family: var(--font-style-heading);
}

h1 {
  font-size: var(--font-size-xxlg);
  margin: 15px 0;
  font-weight: 600;
}

h2 {
  font-size: var(--font-size-xlg);
  margin: 15px 0;
  font-weight: 600;
}

h3 {
  font-size: var(--font-size-lg);
  margin: 10px 0;
  border-bottom-style: dotted;
  border-bottom-width: thin;
  font-weight: 500px;
}

h4 {
  font-size: var(--font-size-m);
  margin: 10px 0;
}

p,
em,
aside,
a {
  color: inherit;
  font-size: var(--font-size-s);
  font-family: var(--font-style-default);
  font-weight: 300;
}

form {
  font-family: inherit;
}

ul {
  margin: 0;
}

.win-stat {
  background-color: #e6f7ef;
}

.loss-stat {
  background-color: #fde0e6;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: var(--font-size-xlg);
    margin: 15px 0;
  }

  h2 {
    font-size: var(--font-size-lg);
    margin: 15px 0;
  }

  h3 {
    font-size: var(--font-size-m);
    margin: 10px 0;
  }

  h4 {
    font-size: var(--font-size-s);
    margin: 10px 0;
  }

  p,
  em,
  aside,
  a {
    color: inherit;
    font-size: var(--font-size-xs);
    font-family: var(--font-style-default);
    font-weight: 300;
  }

  .matchup-table-container {
    max-height: 450px;
  }
}
